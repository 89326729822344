<template>
  <BaseDialog :error="error" :dialog="dialog" @close="close($event)" persistent>
    <template v-slot:title> {{ $t("title") }} </template>
    <template v-slot:text>
      <div class="mb-5">
        {{ $t("text") }}
      </div>

      <DynamicForm
        :fields="fields"
        :formData.sync="formData"
        @is-valid-changed="updateFormValid($event)"
        ref="DynamicForm"
      />
    </template>
    <template v-slot:actionsRight>
      <BaseButton
        :loading="isSaving"
        :text="true"
        @click="close()"
        color="black"
      >
        {{ $t("label.cancel") }}
      </BaseButton>
      <BaseButton :disabled="!formValid" :loading="isSaving" @click="save()">
        {{ $t("label.login") }}
      </BaseButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import DynamicForm from "@/components/base/DynamicForm.vue";
import gql from "graphql-tag";

const initialData = () => ({
  error: null,
  fields: [],
  formData: {},
});

export default {
  name: "exact-online-finance-site-dialog",
  components: {
    BaseButton,
    BaseDialog,
    DynamicForm,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      fields: [],
      formData: {},
      formValid: false,
      isSaving: false,
    };
  },

  computed: {},

  watch: {
    dialog(val) {
      // reset the form to it's original state and reset the validation when dialog closes
      if (!val) {
        this.resetData();
        this.$refs.DynamicForm.resetForm();
      } else {
        this.setFormFields();
        this.setFormData();
      }
    },
  },

  created() {},

  mounted() {},

  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.$emit("closed");
    },

    resetData() {
      // reset the data to it's initial state
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));
    },

    setFormFields() {
      // set the field from the config from the DB
      const fieldsJson = `
        [
            {
                "cols": 12,
                "component": "v-select",
                "items": [
                    "https://start.exactonline.nl",
                    "https://start.exactonline.be",
                    "https://start.exactonline.de",
                    "https://start.exactonline.co.uk",
                    "https://start.exactonline.com",
                    "https://start.exactonline.es"
                ],
                "label": "Site",
                "model": "baseUrl",
                "multiple": false,
                "rules": [
                    "required"
                ]
            }
        ]
      `;
      this.fields = JSON.parse(fieldsJson);
    },

    setFormData(item) {
      const formDataJson = `
        {
					"baseUrl": "https://start.exactonline.nl"
        }
        `;
      this.formData = JSON.parse(formDataJson);
    },

    updateFormValid(isValid) {
      this.formValid = isValid;
    },

    save() {
      if (!this.formValid) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        formData: this.formData,
        formFields: this.fields,
      };

      this.$emit("changed", payload);

      this.isSaving = false;
      this.$emit("update:dialog", false);
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "title": "Site selection",
      "text": "For each country, the Exact Online solution is deployed on a separate site. Because of this, the Exact Online server URL is country dependent. Please select the site your Exact Online environment is hosted."
    },
    "nl": {
      "title": "Site selectie",
      "text": "Voor elk land wordt de Exact Online-oplossing geïmplementeerd op een aparte site. Hierdoor is de Exact Online server-URL afhankelijk van het land. Selecteer de site waar jouw Exact Online-omgeving is gehost."
    },
    "de": {
      "title": "Wähle deine Website aus",
      "text": "Für jedes Land wird die Exact Online-Lösung auf einer separaten Website bereitgestellt. Daher ist die URL des Exact Online-Servers länderabhängig. Bitte wähle die Website aus, auf der deine Exact Online-Umgebung gehostet wird."
    }
  }
  </i18n>
